export default class AudiRangeSlider extends HTMLElement {
	constructor() {
		super();
		this.input = null;
		this.output = null;
		this.outputDynamicText = null;
		this.tickmarks = null;

		this._initializeModule = this._initializeModule.bind(this);
		this._handleInputInteraction = this._handleInputInteraction.bind(this);
	}

	/**
	 * connectedCallback
	 * @returns {void}
	 */
	connectedCallback() {
		this._initializeModule();
	}

	/**
	 * initialize elements
	 * @returns {void}
	 */
	_initializeModule() {
		this._initializeVariables();
		this._updateCSSVariablesForProgressFill();
		this._updateRangeStepsFilling();
		this._updateOutputLabel();

		this._addEvents();
	}

	/**
	 * initialize elements
	 * @returns {void}
	 */
	_initializeVariables() {
		this.input = this.querySelector('.audi-range-slider__input');
		this.output = this.querySelector('.audi-range-slider__label');
		this.tickmarks = this.querySelectorAll(
			'.audi-range-slider__tickmark-item',
		);
		this.outputDynamicText =
			this.output !== null
				? this.output.querySelector(
						'.audi-range-slider__js-dynamic-text',
				  )
				: null;
		this.min = this.input.getAttribute('min') || '0';
		this.max = this.input.getAttribute('max') || '0';
	}

	/**
	 * _addEvents - event binding
	 * @returns {void}
	 */
	_addEvents() {
		this.input.addEventListener('input', this._handleInputInteraction);
		this.input.addEventListener('change', this._handleInputInteraction); // IE11
	}

	/**
	 * _handleInputInteraction - action to be executed on input change
	 * @returns {void}
	 */
	_handleInputInteraction() {
		this._activateSlider();
		this._updateCSSVariablesForProgressFill();
		this._updateRangeStepsFilling();
		this._updateOutputLabel();
	}

	/**
	 * _activateSlider - change the appearance
	 * the custom element can be:
	 * enabled: no data-state
	 * disabled: data-state="disabled" | no user interaction possible
	 * inactive: data-state="inactive" | different appearance until 1st click
	 * @returns {void}
	 */
	_activateSlider() {
		if (this.getAttribute('data-state') === 'inactive') {
			this.removeAttribute('data-state');
		}
	}

	/**
	 * _updateCSSVariablesForProgressFill - set inline css-variables for webkit-progress-fix
	 * @returns {void}
	 */
	_updateCSSVariablesForProgressFill() {
		let styleString =
			'--val: ' +
			this.input.value +
			'; --max: ' +
			this.max +
			'; --min: ' +
			this.min +
			';';

		this.classList.add('audi-range-slider--js-enabled');
		this.input.setAttribute('style', styleString);
	}

	/**
	 * _updateRangeStepsFilling - change data-state for active/inactive tickmarks
	 * @returns {void}
	 */
	_updateRangeStepsFilling() {
		if (this.tickmarks.length) {
			// only execute if the instance is a "step" slider
			[...this.tickmarks].forEach((tickmark) => {
				let optionElement = tickmark.querySelector('option');
				if (
					parseInt(optionElement.value, 10) <
					parseInt(this.input.value, 10)
				) {
					tickmark.setAttribute('data-state', 'filled');
				} else {
					tickmark.removeAttribute('data-state');
				}
			});
		}
	}

	/**
	 * _updateOutputLabel - overwrite label with selected input value or label (if available)
	 * @returns {void}
	 */
	_updateOutputLabel() {
		let dynamicText =
			this.outputDynamicText === null
				? this.output
				: this.outputDynamicText;

		if (dynamicText === null) {
			return;
		}

		dynamicText.innerHTML = this.input.value;

		if (this.tickmarks.length) {
			[...this.tickmarks].forEach((tickmark) => {
				let optionElement = tickmark.querySelector('option');
				if (
					parseInt(optionElement.value, 10) ===
					parseInt(this.input.value, 10)
				) {
					dynamicText.innerHTML = optionElement.label;
				}
			});
		}
	}
}

if (window.customElements.get('audi-range-slider') === undefined) {
	window.customElements.define('audi-range-slider', AudiRangeSlider);
}
