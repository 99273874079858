export default class AudiFilterTag extends HTMLElement {
	constructor() {
		super();

		this._initializeModule = this._initializeModule.bind(this);
	}

	/**
	 * connectedCallback
	 * @returns {void}
	 */
	connectedCallback() {
		this._initializeModule();
	}

	/**
	 * initialize elements
	 * adds class deactivate hover on touch screen preventig the irritating
	 * double click and selection issue
	 * @returns {void}
	 */
	_initializeModule() {
		if ('ontouchstart' in window || navigator.maxTouchPoints) {
			return;
		}
		this.classList.add('audi-filter-tag--no-touch');
	}
}

if (window.customElements.get('audi-filter-tag') === undefined) {
	window.customElements.define('audi-filter-tag', AudiFilterTag);
}
