export default class AudiDropdownFlyout extends HTMLElement {
	constructor() {
		super();

		this.callbackFunction = null;
		this.flyoutItems = null;
		this.labelItem = null;
		this.selectItem = null;

		this._handleOpenClose = this._handleOpenClose.bind(this);
		this._handleClickOutside = this._handleClickOutside.bind(this);
		this._handleItemSelection = this._handleItemSelection.bind(this);
	}

	/**
	 * connectedCallback
	 * @returns {void}
	 */
	connectedCallback() {
		this._initializeModule();
	}

	/**
	 * disconnectedCallback
	 * @returns {void}
	 */
	disconnectedCallback() {
		this._removeEvents();
	}

	/**
	 * initialize elements
	 * @returns {void}
	 */
	_initializeModule() {
		this._initializeVariables();

		this._addEvents();

		if (this.dataset.state === 'open') {
			this._setFlyoutMaxHeight();
		}
	}

	/**
	 * initialize elements
	 * @returns {void}
	 */
	_initializeVariables() {
		this.labelItem = this.querySelector('.audi-dropdown-flyout__label');
		this.selectItem = this.querySelector('.audi-dropdown-flyout__select');
		this.flyoutItems = this.querySelectorAll('.audi-dropdown-flyout__item');
		this.maxHeight =
			(2 * 12 + 24) *
			6; /* max 6 items, line-height plus padding-top and padding-bottom */
	}

	/**
	 * _addEvents - event binding
	 * @returns {void}
	 */
	_addEvents() {
		this.labelItem.addEventListener('click', this._handleOpenClose);
		document.body.addEventListener('click', this._handleClickOutside);

		const flyout = this;

		[...this.flyoutItems].forEach(function (item) {
			item.addEventListener('click', flyout._handleItemSelection);
		});
	}

	/**
	 * _removeEvents - event binding cleanup
	 * @returns {void}
	 */
	_removeEvents() {
		this.labelItem.removeEventListener('click', this._handleOpenClose);
		document.body.removeEventListener('click', this._handleClickOutside);

		const flyout = this;

		[...this.flyoutItems].forEach(function (item) {
			item.removeEventListener('click', flyout._handleItemSelection);
		});
	}

	/**
	 * _handleItemSelection - action to be executed on selection change
	 * @param {Event} event_ - click Event
	 * @returns {void}
	 */
	_handleItemSelection(event_) {
		if (!event_ || !event_.target) {
			return;
		}

		const item = event_.target.closest('.audi-dropdown-flyout__item');

		if (item) {
			this._updateFlyoutState(item);
			this._closeFlyout();
		}
	}

	/**
	 * _triggerCallbackAction - calls callbackfunction
	 * @param {HTMLElement} item_ - selected Flyout Item
	 * @returns {void}
	 */
	_triggerCallbackAction(item_) {
		if (!this.callbackFunction || !item_) {
			return;
		}

		const dataAttributes = item_.dataset;
		this.callbackFunction(dataAttributes);
	}

	/**
	 * _updateFlyoutState - update flyout to current selection
	 * @param {HTMLElement} selectedItem_ - selected Flyout Item
	 * @returns {void}
	 */
	_updateFlyoutState(selectedItem_) {
		const lastSelectedItem = this.querySelector('[data-state="selected"]');

		if (selectedItem_ !== lastSelectedItem) {
			if (lastSelectedItem) {
				lastSelectedItem.dataset.state = null;
			}

			selectedItem_.dataset.state = 'selected';

			this._udpateSelectLabel(selectedItem_);

			this._triggerCallbackAction(selectedItem_);
		}
	}

	/**
	 * _udpateSelectLabel - update select label to current selection
	 * @param {HTMLElement} selectedItem_ - selected Flyout Item
	 * @returns {void}
	 */
	_udpateSelectLabel(selectedItem_) {
		let labelText = '';

		if (selectedItem_.querySelector('.audi-link-m__text')) {
			labelText =
				selectedItem_.querySelector('.audi-link-m__text').innerText;
		} else {
			labelText = selectedItem_.innerText;
		}

		this.labelItem.querySelector(
			'.audi-dropdown-flyout__current-label',
		).innerText = labelText;
	}

	/**
	 * _handleClickOutside - checks for click out side and closes flyout if required
	 * @param {Event} event_ - click Event
	 * @returns {void}
	 */
	_handleClickOutside(event_) {
		if (event_ && event_.target && !this.contains(event_.target)) {
			this._closeFlyout();
		}
	}

	/**
	 * _handleOpenClose - toggles open close state of flyout
	 * @returns {void}
	 */
	_handleOpenClose() {
		if (this.dataset.state === 'open') {
			this._closeFlyout();
		} else {
			this._openFlyout();
		}
	}

	/**
	 * _closeFlyout - set flyout state to closed
	 * @returns {void}
	 */
	_closeFlyout() {
		this.dataset.state = 'closed';
		this._removeFlyoutMaxHeight();
	}

	/**
	 * _openFlyout - set flyout state to open
	 * @returns {void}
	 */
	_openFlyout() {
		this.dataset.state = 'open';
		this._setFlyoutMaxHeight();
	}

	/**
	 * _setFlyoutMaxHeight - set height of flyout
	 * @returns {void}
	 */
	_setFlyoutMaxHeight() {
		if (window.innerWidth >= 768) {
			let actualHeight = 0;

			const items = this.querySelectorAll(
				'.audi-dropdown-flyout__select-group',
			);
			[...items].forEach(function (item) {
				actualHeight += item.getBoundingClientRect().height;
			});

			const newMaxHeight =
				actualHeight < this.maxHeight ? actualHeight : this.maxHeight;
			this.selectItem.style.maxHeight = newMaxHeight + 'px';
		} else {
			const labelHeight = this.labelItem.getBoundingClientRect().height;
			this.selectItem.style.maxHeight =
				'calc(100vh - ' + labelHeight + 'px)';
		}
	}

	/**
	 * _removeFlyoutMaxHeight - removes Style Attribute
	 * @returns {void}
	 */
	_removeFlyoutMaxHeight() {
		this.selectItem.style.maxHeight = '';
	}

	/**
	 * Subscribe `callbackFunction` to the flyout
	 * The given callback is called, every time the state of the flyout changes.
	 * @param {function} callbackFunction - A named callback.
	 * @returns {void}
	 */
	subscribeToFlyout(callbackFunction) {
		this.callbackFunction = callbackFunction;
	}

	/**
	 * Select flyout item by Id
	 * @param {String} id_ - unique item id
	 * @returns {void}
	 */
	selectItemById(id_) {
		if (!id_) {
			return;
		}

		const item = this.querySelector('[data-id="' + id_ + '"');
		if (item) {
			this._updateFlyoutState(item);
		}
	}

	/**
	 * return selected item data
	 * @param {String} id_ - unique item id
	 * @returns {Object} object with selected item attributes
	 */
	getSelectedItemData() {
		let selectedItemData = {};

		const item = this.querySelector('[data-state="selected"]');

		if (item) {
			selectedItemData = item.dataset;
		}

		return selectedItemData;
	}
}

if (window.customElements.get('audi-dropdown-flyout') === undefined) {
	window.customElements.define('audi-dropdown-flyout', AudiDropdownFlyout);
}
